import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Support = ({ iconColor, ...props }) => {
    return (
        <SvgIcon {...props} viewBox="0 0 50 13">
            <g stroke="none" width="50" height="50" viewBox="0 0 50 13" fill="none">
                <path d="M24.9993 0.353027C21.3194 0.353027 18.3358 3.00583 18.3358 6.27778C18.3358 9.54973 21.3194 12.2025 24.9993 12.2025C28.6792 12.2025 31.6627 9.54973 31.6627 6.27778C31.6627 3.00583 28.6792 0.353027 24.9993 0.353027Z" fill={iconColor} />
                <path d="M38.7122 6.27949C38.7122 3.81059 40.4117 1.69876 42.8264 0.808365C42.0405 0.518717 41.1804 0.354736 40.2755 0.354736C36.5956 0.354736 33.6121 3.00754 33.6121 6.27949C33.6121 9.55143 36.5956 12.2042 40.2755 12.2042C41.1804 12.2042 42.0405 12.0403 42.8264 11.7506C40.4117 10.8602 38.7122 8.74686 38.7122 6.27949Z" fill={iconColor} />
                <path d="M44.3207 6.27949C44.3207 3.30485 46.7872 0.84208 50 0.419102C49.6863 0.377724 49.3657 0.354736 49.0399 0.354736C45.36 0.354736 42.3765 3.00754 42.3765 6.27949C42.3765 9.55143 45.36 12.2042 49.0399 12.2042C49.3657 12.2042 49.6863 12.1813 50 12.1399C46.7872 11.7169 44.3207 9.25412 44.3207 6.27949Z" fill={iconColor} />
                <path d="M11.2879 6.27778C11.2879 8.74668 9.58847 10.8585 7.17371 11.7489C7.95967 12.0386 8.81975 12.2025 9.72464 12.2025C13.4045 12.2025 16.3881 9.54973 16.3881 6.27778C16.3881 3.00583 13.4045 0.353027 9.72464 0.353027C8.81975 0.353027 7.95967 0.517008 7.17371 0.806656C9.58847 1.69705 11.2879 3.81041 11.2879 6.27778Z" fill={iconColor} />
                <path d="M5.67927 6.27778C5.67927 9.25242 3.2128 11.7152 0 12.1382C0.313696 12.1795 0.634287 12.2025 0.960048 12.2025C4.63994 12.2025 7.6235 9.54973 7.6235 6.27778C7.6235 3.00583 4.63994 0.353027 0.960048 0.353027C0.634287 0.353027 0.313696 0.376015 0 0.417394C3.2128 0.840371 5.67927 3.30314 5.67927 6.27778Z" fill={iconColor} />
            </g>
        </SvgIcon>
    );
};

export default Support;