import React, { useState, useEffect, useRef } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
    Elements,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import MyDNATextInput from '../../Shared/MyDNATextInput';
import { makeStyles, Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
import { commitCustomerDetails } from '../../HttpHelpers/CustomerServiceHttpHelper';
import { paymentDetailsCaptured, paymentDetailsCaptureFailed, paymentResponseCaptured } from '../../Actions/CustomerDetailsActions';
import { push } from 'connected-react-router';
import { Formik, Form } from 'formik';
import MyDNACardNumber from './MyDNACardNumber';
import MyDNACardCvCElement from './MyDNACardCvCElement';
import MyDNAExpiryElement from './MyDNAExpiryElement';
import { connect, useDispatch } from 'react-redux';
import { verifyPaymentMethod, initialValues, validationSchema } from './PaymentService';
import ComponentButtons from '../../Shared/ComponentButtons';
import { sendRequiredRiskAssessment } from '../../HttpHelpers/EventsHttpHelper';
import { getRiskAssessmentFormByName } from '../../HttpHelpers/CRMFormsHttpHelper';
import PaymentSummary from './PaymentSummary';
import { Typography } from '@material-ui/core';
import SelectComponent from '../../Shared/SelectComponent';
import CheckboxInput from '../../Shared/CheckboxInput';
import { QueryParameterNames, SubscriptionGroups, TermsConditionsLink } from '../../Shared/Constants';
import StyledButton from '../../components/StyledButton';
import { getPregnancyAssessmentReport } from '../BriefAssessment/BriefAssessmentService';
import { sendLinkClick } from '../../Analytics/AnalyticsHelper';
import { getHealthInsuranceList } from './HealthInsuranceList'; 
import { getInsurerList } from './InsurerList';
import { getBillingDetails } from '../../HttpHelpers/UserServiceHttpHelper';

const useStyles = makeStyles(theme => ({
    formContainer: {
        display: 'inline-block',
        textAlign: 'left',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row-reverse'
        },
    },
    leftPart: {
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '384px',
        [theme.breakpoints.up('md')]: {
            marginRight: '97px', 
        }
    },
    rightPart: {
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.up('md')]: {
            marginLeft: '126px',
        }
    },
    verticalDivider: {
        height: '0px',
        width: '0px',
        backgroundColor: '#000',
        [theme.breakpoints.up('md')]: {
            backgroundColor: '#3F3F3F',
            height: '339px',
            width: '5px'
        }
    },
    flexContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: '5%'
    },
    sectionContainer: {
        marginBottom: '60px',
        textAlign: 'left'
    }
}));

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const CheckoutForm = (props) => {
    const dispatch = useDispatch();
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);
    const [errorToDisplay, setErrorToDisplay] = useState(null);
    const [cardNumberErrorMessage, setCardNumberErrorMessage] = useState(null);
    const [cvcErrorMessage, setCvcErrorMessage] = useState(null);
    const [expiryDateErrorMessage, setExpiryDateErrorMessage] = useState(null);
    const [programName, setProgramName] = useState(null);
    const [flow, setFlow] = useState(null);
    const [termsAndConditions, setTermsAndConditions] = useState(false);
    const [pregnancyDueDate, setPregnancyDueDate] = useState();
    const [phiChoices, setPhiChoices] = useState(null);
    const [insurerChoices, setInsurerChoices] = useState(null);
    const [phiNameVisibility, setPhiNameVisibility] = useState(false);
    const [insuranceMembershipNumberVisibility, setInsuranceMembershipNumberVisibility] = useState(false);
    const [existingBillingDetailsVisibility, setExistingBillingDetailsVisibility] = useState(false);
    const [existingBillingDetails, setExistingBillingDetails] = useState(null);
    const [useExistingCard, setUseExistingCard] = useState(false);

    const classes = useStyles();
    const formikRef = useRef();
    useEffect(() => {
        const searchQuery = props.router.location.search;
        const urlParams = new URLSearchParams(searchQuery);
        const programName = urlParams.get(QueryParameterNames.P) ?? '';
        const flow = urlParams.get(QueryParameterNames.F) ?? '';
        const pregnancyReport = programName === SubscriptionGroups.PRG ? getPregnancyAssessmentReport(props.briefAssessmentForm) : null;
        if (pregnancyReport) {
            setPregnancyDueDate(pregnancyReport.dueDate);
        }
        setProgramName(programName);
        setFlow(flow);
        if(!props?.subscription) {
            dispatch(push(props.previousPage + (programName?.length > 0 ? `?p=${programName}` : '')));
        }
        const phiChoices = getHealthInsuranceList().map(v =>({value: v.name, displayText: v.name}));
        phiChoices.unshift({ value: '', displayText: '-- Please Select --'});
        setPhiChoices(phiChoices);
        const insurers = getInsurerList().map(v =>({value: v.name, displayText: v.name}));
        insurers.unshift({ value: '', displayText: '-- Please Select --'});
        setInsurerChoices(insurers);

        initExistingBillingDetails();
    }, []);

    async function initExistingBillingDetails()
    {
        const billingResponse = await getBillingDetails(props.auth.user.signInUserSession.accessToken.jwtToken);
        if (billingResponse && billingResponse.ok)
        {
            const billingDetails = await billingResponse.json();
            if (billingDetails && billingDetails.isSuccess && billingDetails.result)
            {
                setExistingBillingDetailsVisibility(true);
                setUseExistingCard(true);
                setExistingBillingDetails(billingDetails.result);
                if (formikRef.current) {
                    formikRef.current.setFieldValue('addressLine1', billingDetails.result.billingAddress.line1);
                    formikRef.current.setFieldValue('addressLine2', billingDetails.result.billingAddress.line2);
                    formikRef.current.setFieldValue('suburbCity', billingDetails.result.billingAddress.city);
                    formikRef.current.setFieldValue('stateProvince', billingDetails.result.billingAddress.state);
                    formikRef.current.setFieldValue('postalCode', billingDetails.result.billingAddress.postalCode);
                    formikRef.current.setFieldValue('country', billingDetails.result.billingAddress.country);
                }

            }
        }
    }
    async function createSubscription(subscriptionCode, paymentData) {
        const paymentDetails = {
            paymentMethodId: subscriptionCode,
            ...paymentData,
        };

        const customerDetails = {
            ...props,
            pregnancyDueDate,
            payment: paymentDetails,
            insuranceMembershipNumber: paymentData.insuranceMembershipNumber, 
            insurer: paymentData.insurer,
            insurerName: paymentData.insurerName
        };

        dispatch(paymentDetailsCaptured(paymentDetails));
        commitCustomerDetails(customerDetails)
            .then(async (response) => {
                if(!response.ok) {
                    response.json()
                        .then((data) => {
                            dispatch(paymentDetailsCaptureFailed(data));
                            setLoading(false);
                            setErrorToDisplay(data);
                        });
                } else {
                    if (programName?.length > 0 )
                    {
                        const token = props.auth?.user?.signInUserSession?.accessToken?.jwtToken;
                        const riskAssessmentFormResult = await getRiskAssessmentFormByName(token);
                        if (!riskAssessmentFormResult.ok)
                            await sendRequiredRiskAssessment(token);
                    }
                    const responseBody = await response.json();
                    dispatch(paymentResponseCaptured(responseBody));
                    dispatch(push(`${props.nextPage}?p=${programName}&f=${flow}`));
                }
            })
            .catch((err) => {
                dispatch(paymentDetailsCaptureFailed(err.message));
                setLoading(false);
            });
    }
    const handleSubmit = async (paymentData) => {
        if (useExistingCard)
        {
            setLoading(true);

            sendLinkClick('Confirm Pay Button', 'Button',`${props.nextPage}`,window.location.pathname,'');

            const subscriptionCode = existingBillingDetails.paymentMethodId;
            await createSubscription(subscriptionCode, paymentData);
        }
        else
        {
            setLoading(true);
        
            const cardNumberElement = elements.getElement('cardNumber');

            // Use your card Element with other Stripe.js APIs
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardNumberElement,
            });
            if(error) {
                setLoading(false);
                return;
            }
        
            sendLinkClick('Confirm Pay Button', 'Button',`${props.nextPage}`,window.location.pathname,'');

            const subscriptionCode = paymentMethod.id;
            await createSubscription(subscriptionCode, paymentData);
        }
      

       
    };

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const goPrevStep = () => {
        props.dispatch(push(`${props.previousPage}${programName ? '?p=' + programName : ''}&f=${flow}`));
    };
    const onChangeInsurerHandler= (value,setFieldValue) => {
        if (value)
        {
            var isPhi = value == 'Private Health Insurance';
            setPhiNameVisibility(isPhi);
            setInsuranceMembershipNumberVisibility(true);
            setFieldValue('insurerNameIsVisible', isPhi);            
            setFieldValue('insuranceMembershipNumberIsVisible', true);
        }
        else
        {
            setPhiNameVisibility(false);
            setInsuranceMembershipNumberVisibility(false);
            setFieldValue('insuranceMembershipNumber', '');
            setFieldValue('insurerName','');

            setFieldValue('insuranceMembershipNumberIsVisible', false);
            setFieldValue('insurerNameIsVisible', false);
        }    
        
    };
    const handleCardOptionChange = (value) => {
        const useExistingCardValue = value == 'true';
        setUseExistingCard(useExistingCardValue);
    };
    return (
        <div id="payment-form" className={classes.root}>
            <Formik
                innerRef={formikRef}
                enableReinitialize={false}
                validateOnChange={true}
                initialValues={
                    {
                        ...initialValues,
                        firstName: props.auth?.user?.firstName,
                        lastName: props.auth?.user?.lastName,
                        country: 'Australia',
                        price: props.subscription.initialChargeAmount ? props.subscription.initialChargeAmount : props.subscription.InstalmentAmount,
                        coupon: props.couponDetails?.promoCode,
                        discountValue: 0,
                        insurerName: '',
                        insurerNameIsVisible: false,
                        insuranceMembershipNumber: '',
                        insuranceMembershipNumberIsVisible: false,
                        insurer:  '',
                    }}
                validationSchema={validationSchema}
                onSubmit={(data, { setSubmitting }) => {
                    if (existingBillingDetailsVisibility)
                    {
                        setSubmitting(true);
                        handleSubmit(data);
                        setSubmitting(false);
                    }
                    else
                    {
                        if (termsAndConditions) {
                            setSubmitting(true);
                            handleSubmit(data);
                            setSubmitting(false);    
                        } else {
                            setErrorToDisplay('Please accept the terms and conditions.');
                        }
                    }
                }}      
            >

                {({ errors, values, setFieldValue }) => (
                    <Form>
                        <form id="payment-form">
                            <div className={classes.formContainer}>
                                <div className={classes.rightPart}>
                                    <PaymentSummary values={values} setFieldValue={setFieldValue} dispatch={dispatch} />
                                </div>
                                <div className={classes.verticalDivider} />                               
                                <div className={classes.leftPart}>
                                    {
                                        (!props.auth?.user?.firstName && !props.auth?.user?.firstName) &&
                                        <div className={classes.sectionContainer}>
                                            <Typography variant='h2'>{'Personal Details'}</Typography>
                                            <MyDNATextInput
                                                name="firstName"
                                                label="First Name"
                                                maxLength="80"
                                                setFieldValue={setFieldValue}
                                                validationErrorText={errors['firstName']?.errorText}
                                                value={values.firstName}
                                            />
                                            <MyDNATextInput
                                                name="lastName"
                                                label="Last Name"
                                                maxLength="80"
                                                setFieldValue={setFieldValue}
                                                validationErrorText={errors['lastName']?.errorText}
                                                value={values.lastName}
                                            />
                                        </div>
                                    }
                                    {                                       
                                        <div className={classes.sectionContainer}>
                                            <Typography variant='h2'>Private Health Insurance / Third Party Insurance</Typography>
                                            <Typography variant='h4'>If you would like to claim with Private Health Insurance or other Third Party insurance, please select below.</Typography>
                                            <SelectComponent 
                                                name="insurer"
                                                label="Insurer"
                                                setFieldValue={setFieldValue}
                                                value={values.insurer}
                                                validationErrorText={errors['insurer']?.errorText}
                                                choices={insurerChoices}
                                                onChange={(event)=> {
                                                    onChangeInsurerHandler(event.target.value, setFieldValue);
                                                }}
                                            />
                                            { phiNameVisibility && 
                                                <SelectComponent 
                                                    name="insurerName"
                                                    label="Private Health Insurance Name"
                                                    setFieldValue={setFieldValue}
                                                    value={values.insurerName}
                                                    validationErrorText={errors['insurerName']?.errorText}
                                                    choices={phiChoices}
                                                    helperStyle={{
                                                        color: '#FF0000',
                                                        fontSize: '16px'
                                                    }}
                                                />
                                            }
                                            { insuranceMembershipNumberVisibility && 
                                                <MyDNATextInput
                                                    name="insuranceMembershipNumber"
                                                    label="Membership Number"
                                                    maxLength="20"
                                                    setFieldValue={setFieldValue}
                                                    validationErrorText={errors['insuranceMembershipNumber']?.errorText}
                                                    value={values.insuranceMembershipNumber}
                                                    helperStyle={{
                                                        width: '100%',
                                                    }}
                                                />
                                            }

                                        </div>
                                    }
                                    <div className={classes.sectionContainer}>
                                        <Typography variant='h2'>{'Billing address'}</Typography>
                                        <MyDNATextInput
                                            name="addressLine1"
                                            label="Address line 1"
                                            maxLength="80"
                                            setFieldValue={setFieldValue}
                                            value={values.addressLine1}
                                            validationErrorText={errors['addressLine1']?.errorText}
                                        />
                                        <MyDNATextInput
                                            name="addressLine2"
                                            label="Address line 2 (optional)"
                                            maxLength="80"                                            
                                            value={values.addressLine2}
                                            setFieldValue={setFieldValue}
                                        />
                                        <SelectComponent 
                                            name="country"
                                            label="Country"
                                            setFieldValue={setFieldValue}
                                            value={values.country}
                                            validationErrorText={errors['country']?.errorText}
                                            choices={[
                                                {
                                                    value: 'Australia',
                                                    displayText: 'Australia'
                                                }
                                            ]} // note: Only allow 'Australia' atm
                                        />
                                        <MyDNATextInput
                                            name="suburbCity"
                                            label="Suburb/City"
                                            maxLength="80"
                                            value={values.suburbCity}
                                            setFieldValue={setFieldValue}
                                            validationErrorText={errors['suburbCity']?.errorText}
                                        />
                                        <div className={classes.flexContainer}>                                            
                                            <MyDNATextInput
                                                name="postalCode"
                                                label="Post code"
                                                maxLength="80"
                                                value={values.postalCode}
                                                setFieldValue={setFieldValue}
                                                validationErrorText={errors['postalCode']?.errorText}
                                            />
                                                  
                                            <MyDNATextInput
                                                name="stateProvince"
                                                label="State"
                                                maxLength="80"
                                                value={values.stateProvince}
                                                setFieldValue={setFieldValue}
                                                validationErrorText={errors['stateProvince']?.errorText}
                                            />
                                        </div>
                                        
                                    </div>
                                    <div className={classes.sectionContainer}>
                                        <Typography variant='h2'>{'Card details'}</Typography>
                                        {
                                            useExistingCard && existingBillingDetails && existingBillingDetails.card &&
                                            <RadioGroup
                                                aria-labelledby="cardOptionsGroup"
                                                name="cardOptionsGroup"
                                                value={useExistingCard}
                                                row
                                                onChange={(e) => handleCardOptionChange(e.target.value)}
                                            >
                                                <FormControlLabel value={true} control={<Radio />} label="Use Existing Card" />
                                                <FormControlLabel value={false} control={<Radio />} label="Add New Card" />
                                            </RadioGroup>
                                        }
                                    </div>
                                    {
                                        useExistingCard && existingBillingDetails && existingBillingDetails.card &&
                                        <>
                                            <div className={classes.sectionContainer}>
                                                <Typography variant='h4'>Use card details below to continue and pay</Typography>
                                                <Typography variant='body1' style={{ textTransform: 'uppercase'}}>{existingBillingDetails.card.brand}</Typography>
                                                <Typography variant='body1'>Card number ending with {existingBillingDetails.card.last4}</Typography>
                                                <Typography variant='body1'>Expiry {existingBillingDetails.card.expMonth}/{existingBillingDetails.card.expYear}</Typography>
                                            </div>
                                        </>
                                    }
                                   
                                    { !useExistingCard &&
                                    <>
                                        <div className={classes.sectionContainer}>
                                            <Typography variant='h4'>Enter card details to continue and pay</Typography>
                                            <MyDNATextInput
                                                name="nameOnCard"
                                                label="Name on card"
                                                maxLength="80"
                                                setFieldValue={setFieldValue}
                                                validationErrorText={errors['nameOnCard']?.errorText}
                                            />
                                            <MyDNACardNumber error={cardNumberErrorMessage}/>
                                            <div className={classes.flexContainer}>
                                                <MyDNACardCvCElement error={cvcErrorMessage}/>
                                                <MyDNAExpiryElement error={expiryDateErrorMessage}/>
                                            </div>
                                            <CheckboxInput
                                                name={'termsAndConditions'}
                                                value={values.termsAndConditions}
                                                onChange={(event)=>{
                                                    setTermsAndConditions(event.target.checked);
                                                }}
                                            />
                                            <span className={'terms-conditions-font'}>I agree to the{' '}
                                                <a
                                                    href={TermsConditionsLink}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className={'TermsAndConditionsLink'}
                                                >Terms & Conditions
                                                </a>
                                            </span>
                                        </div></>
                                    }
                                   
                                    
                                </div>
                            </div>
                            <div className="inline-buttons">
                                <StyledButton roundedInverse style={{ marginRight: '16px' }} type="button" onClick={(event) => goPrevStep(event)}>BACK</StyledButton>
                                <ComponentButtons
                                    buttonOnly
                                    previousPage={0}
                                    singleButton={true}
                                    isSubmitting={loading}
                                    submitText="Confirm & Pay"
                                    disableArrows={true}
                                    onClick={() => {
                                        verifyPaymentMethod(setCardNumberErrorMessage, setCvcErrorMessage, setExpiryDateErrorMessage, elements);
                                    }}
                                    rounded
                                />
                            </div>
                            <div className="error inline-buttons">
                                {errorToDisplay}
                            </div>
                        </form>
                    </Form>)}
            </Formik>
        </div >
    );
};

const PaymentForm = (props) => (
    <Elements stripe={stripePromise}>
        <CheckoutForm {...props} />
    </Elements>
);

function mapStateToProps(state) {
    return {
        ...state.customerDetails,
        subscription: state.customerDetails.subscription,
        couponDetails: state.customerDetails.couponDetails,
        router: state.router
    };
}

export default connect(mapStateToProps)(PaymentForm);