
import { SubscriptionGroups, PregnancyProgramPages, ProgramPages, FollowUpBookingPages, VpaPages, 
    OrganisationRegistrationPages, InStudioRegistrationPages, DailyWorkoutPages, PhysioRecommendationPages,
    AUTH_ACCESS_METHOD } from '../Constants';


export const getApplicablePages = (flow, programName) => {
    let applicablePages;
    
    if(programName) {
        switch(flow) {
            case AUTH_ACCESS_METHOD.FOLLOWUP_BOOKING:
            {
                applicablePages = {
                    pages: FollowUpBookingPages, 
                    steps: 2
                };    
                break;
            }
            case AUTH_ACCESS_METHOD.PHYSIO_RECOMMENDATION:
            {
                applicablePages = {
                    pages: PhysioRecommendationPages, 
                    steps: 2
                };    
                break;
            }
            default:
            {
                switch (programName)
                {
                    case SubscriptionGroups.PRG:
                    {               
                        applicablePages = {
                            pages: PregnancyProgramPages, 
                            pagesSteps: 3
                        };
                        break;
                    }
                    case SubscriptionGroups.VPA:
                    {          
                        applicablePages = {
                            pages: VpaPages, 
                            steps: 4
                        };          
                        break;
                    }
                    default:
                    {
                        applicablePages = {
                            pages: ProgramPages, 
                            steps: 3
                        };       
                    }
                }
            }
        }

      
    }

    else {          
        if(flow === AUTH_ACCESS_METHOD.ORGANISATION_REGISTRATION) {
            applicablePages = {
                pages: OrganisationRegistrationPages, 
                steps: 3
            };    
          
        }
        else if(flow === AUTH_ACCESS_METHOD.ISA_REGISTRATION) {
            applicablePages = {
                pages: InStudioRegistrationPages, 
                steps: 2
            };
        }
        else
        {
            applicablePages = {
                pages: DailyWorkoutPages,
                steps: 3,
                isPrivateRoute: true
            };
        }
    }
    return applicablePages;
};

export const getApplicablePageInfo = (flow, programName, currentPath) => {
    let applicablePages = getApplicablePages(flow, programName);
    
    const currentPage = applicablePages.pages.filter(p => p.path === currentPath)[0];
    return { currentPage, steps: applicablePages.steps} ;
};