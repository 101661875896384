import { CustomerServiceEndpoints } from './HttpConstants';
import { CUSTOMER_MICROSERVICE_BASE_URL } from '../Shared/Configs';
import { Auth } from 'aws-amplify';
import { SessionStorageVariables } from '../Shared/Constants';
const UNIVERSALPRACTICE_KITTYPE=30000;

export async function updateCustomerDetails(store) {
    //Adding UniversalPracticeUser as the user as DynamoDb needs last name and the call fails.
    let customerDetails = {
        lastName: 'UniversalPracticeUser',
        emailAddress: store.auth.user.username,
        timeZone: store.userDetails.timeZone,
        promoCode: store?.couponDetails?.promoCode ?? null,
        subscriptionCode: store.subscription.crmProductCode,
        recurringPaymentPriceId: store.subscription.priceId
    };
    return callUpdateRegistrationDetails(customerDetails);
}

export async function callUpdateRegistrationDetails(customerDetails) {
    return fetch(
    `${CUSTOMER_MICROSERVICE_BASE_URL}${CustomerServiceEndpoints.UpdateRegistrationDetails}`,
    {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(customerDetails),
    }
    );
}

export async function commitCustomerDetails(store) {
    //refresh token
    await Auth.currentSession();
    let customerDetails = {
        emailAddress: store.auth.user.username,
        dob: store.auth.user.dob,
        timeZone: store.appointment?.tz ?? store.userDetails.timeZone ,
        promoCode: store?.couponDetails?.promoCode ?? null,
        subscriptionCode: store?.subscription.crmProductCode,
        addressLine1: store?.payment?.addressLine1,
        suburbCity: store?.payment?.suburbCity,
        stateProvince: store?.payment?.stateProvince,
        postalCode: store?.payment?.postalCode,
        firstName: store?.payment?.firstName ?? store.auth.user.firstName ?? null,
        lastName: store?.payment?.lastName ?? store.auth.user.lastName ?? null,
        country: store.payment?.country,
        paymentMethodId: store?.payment?.paymentMethodId,
        priceId: store?.payment?.priceId,
        crmPatientId: store?.auth?.user?.patientId,
        initialAppointment: store?.appointment,
        sessionToken: store.auth.user.signInUserSession.accessToken.jwtToken,
        organisationExternalKey: store.organisation ? store.organisation.externalKey : null,
        pregnancyDueDate: store?.pregnancyDueDate,
        insuranceMembershipNumber: store?.insuranceMembershipNumber, 
        insuranceInsurer: store?.insurer,
        insuranceInsurerName: store?.insurerName
    };

    if (customerDetails.initialAppointment)
        customerDetails.initialAppointment.briefAssessmentFormId = store.briefAssessmentForm?.id;

    var prdReferralId = sessionStorage.getItem(
        SessionStorageVariables.PrdReferralId
    );
	
    if (prdReferralId) {
        customerDetails.recommendationReferralId = prdReferralId;
    }

    var prdReferralName = sessionStorage.getItem(
        SessionStorageVariables.PrdReferralName
    );
    if (prdReferralName) {
        customerDetails.recommendationReferralName = prdReferralName;
    }

    return callCommitRegistrationDetails(customerDetails);
}

async function callCommitRegistrationDetails(customerDetails) {
    return fetch(
    `${CUSTOMER_MICROSERVICE_BASE_URL}${CustomerServiceEndpoints.CommitRegistrationDetails}`,
    {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(customerDetails),
    }
    );
}

export async function getSubscriptionDetails(programName, partner = 'Universal%20Practice') {

    return fetch(`${CUSTOMER_MICROSERVICE_BASE_URL}/RegisterMicroService/Register/ProductSubscriptionFreightOptions?Country=Australia&Partner=${partner}&ProgramName=${programName ?? 'mbs'}`);
}

export async function verifyCoupon(promoCode, subscriptionCode, priceId) {
    return fetch(`${CUSTOMER_MICROSERVICE_BASE_URL}/RegistrationOptions/v2/VerifyPromoCode?Partner=Universal%20Practice&promoCode=${promoCode}&subscriptionCode=${subscriptionCode}&kitType=${UNIVERSALPRACTICE_KITTYPE}&priceId=${priceId}`);
}

export async function getOrganisationDetails(organisationKey) {
    return fetch(`${CUSTOMER_MICROSERVICE_BASE_URL}/RegisterMicroService/Register/Organisation/Key/${organisationKey}`);
}