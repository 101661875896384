import {  FormControlLabel, makeStyles, Radio, RadioGroup } from '@material-ui/core';
import { connect } from 'react-redux';
import React from 'react';
import { Typography } from '@material-ui/core';
import { TermsConditionsLink } from '../../Shared/Constants';
import Coupon from './Coupon';
import { verifyCouponApplication } from '../Subscription/SubscriptionServices';
import { couponDetailsCaptured, couponDetailsReset } from '../../Actions/CustomerDetailsActions';
import { useState, useEffect } from 'react';

const useStyles = makeStyles(theme => ({
    root: {
        width: '384px',
        backgroundColor: '#1C1C1C',
        padding: '23px',
        textAlign: 'left',
        marginBottom: '80px'
    },
    rootMobile: {
        width: 'auto',
        backgroundColor: '#1C1C1C',
        padding: '23px',
        textAlign: 'left',
        marginBottom: '80px'
    },
    productContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '40px'
    },
    couponContainer: {
        marginBottom: '40px',
        display: 'flex',
        justifyContent: 'space-between',
    },
    totalContainer: {
        borderTop: '1px solid #ffffff99', 
        marginTop: 8,
        paddingTop: 16,
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '33px',
    }, 
    notesContainer: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '1em',
        marginBottom: '7px', 
        color: '#fff'
    },
    productInstalment: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10
    },
    instalmentOption: {
        display: 'flex',
        gap: 10,
        paddingTop: 5,
    }
}));

const PaymentSummary = (props) => {
    const classes = useStyles();
    const [errorMessage, setErrorMessage] = useState('');
    const [amountOff, setAmountOff] = useState('');
    const [selectedPriceId, setSelectedPriceId] = useState('');
    const [totalPrice, setTotalPrice] = useState(0);
    const [discountValue, setDisountValue] = useState(0);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const handleWindowSizeChange = () =>  {
        setIsMobile(window.innerWidth <= 768);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);
    
    useEffect(() => {
        if(props?.values?.coupon === ''){
            couponProcess(props?.values);
        } else {
            setAmountOff('');
        }
    }, [props?.values?.coupon]);

    useEffect(() =>{
        if (props.subscription) {
            setSelectedPriceId(props.subscription.priceId);
        }
    }, [props.subscription]);

    useEffect(() => {
        if (selectedPriceId?.length > 0) {
            let price = 0;
            if (selectedPriceId === props.subscription.priceId) {
                price = props.subscription.initialChargeAmount;
            } else if (selectedPriceId === props.subscription.instalmentPriceId) {
                price = props.subscription.instalmentAmount * props.subscription.numberOfInstalment;
            }

            if (props.couponDetails && props.couponDetails.isValid && props.couponDetails.products.length > 0) {
                const applicableProduct =props.couponDetails.products[0];
                if (applicableProduct.duration === 'once') {
                    let discountedValue = (selectedPriceId === props.subscription.priceId) ? props.subscription.initialChargeAmount : props.subscription.instalmentAmount;
                    if (applicableProduct.percentOff !== null) {
                        setAmountOff(`${applicableProduct.percentOff}%`);
                        discountedValue = (discountedValue * applicableProduct.percentOff / 100);
                    } else if (applicableProduct.amountOff !== null) {
                        setAmountOff(`$${applicableProduct.amountOff}`);
                        discountedValue = applicableProduct.amountOff;
                    }
                    price -= discountedValue;
                    setDisountValue(discountedValue);
                } else {
                    let discountedValue = (selectedPriceId === props.subscription.priceId) ? props.subscription.initialChargeAmount : (props.subscription.instalmentAmount * props.subscription.numberOfInstalment);
                    if (applicableProduct.percentOff !== null) {
                        setAmountOff(`${applicableProduct.percentOff}%`);
                        discountedValue = (discountedValue * applicableProduct.percentOff / 100);
                    } else if (applicableProduct.amountOff !== null) {
                        const numberOfPayment = (selectedPriceId === props.subscription.priceId) ? 1 : props.subscription.numberOfInstalment;
                        const amountOff = applicableProduct.amountOff * numberOfPayment;
                        setAmountOff(`$${amountOff}`);
                        discountedValue = amountOff;
                    }
                    price -= discountedValue;
                    setDisountValue(discountedValue);    
                }
            } else {
                setAmountOff('');
                setDisountValue(0);
            }
            props.setFieldValue('priceId', selectedPriceId);
            props.setFieldValue('price', price);

            setTotalPrice(price);
        }
    }, [selectedPriceId, props.couponDetails]);

    useEffect(() => {
        props.setFieldValue('discountValue', discountValue);
    }, [discountValue]);


    const couponProcess = async (values) => {
        if (values?.coupon !== '') {
            let data = await verifyCouponApplication(values?.coupon, props.subscription.crmProductCode, selectedPriceId);
            if (data?.isValid) {
                props.dispatch(couponDetailsCaptured({ ...data, promoCode: values?.coupon }));
                props.setFieldValue('couponValid', data.isValid);
                setErrorMessage('');
            } else {
                props.setFieldValue('couponValid', false);
                setErrorMessage('Invalid coupon');
            }
        } else {
            props.setFieldValue('couponValid', false);
            setErrorMessage('');
        }
    };

    const clearCoupon = (values) => {
        props.setFieldValue('couponValid', false);
        props.setFieldValue('coupon', '');
        props.dispatch(couponDetailsReset());
    };
    
    return (
        <div id="payment-summary" className={isMobile ? classes.rootMobile : classes.root}>
            <Typography variant='h2'>Summary</Typography>
            {
                (props.subscription.instalmentPriceId?.length > 0 && props.subscription.numberOfInstalment > 0) ?
                    <div className={classes.productInstalment}>
                        <div>
                            <Typography>{props.subscription.name}</Typography>
                            <Typography variant='body2' color='textSecondary'>
                                {`${props.subscription.subscriptionInterval} ${props.subscription.subscriptionIntervalUnit}${props.subscription.subscriptionInterval > 1 ? 's': ''}`}                      
                            </Typography>
                        </div>
                        <div>
                            
                            <Typography id="payment-method-label">Payment options:</Typography>
                            <RadioGroup
                                aria-labelledby="payment-method-label"
                                value={selectedPriceId}
                                onChange={(event) => setSelectedPriceId(event.target.value) }>
                                <FormControlLabel value={props.subscription.priceId} control={<Radio />} label={`$ ${props.subscription.initialChargeAmount} up-front`} />
                                <FormControlLabel value={props.subscription.instalmentPriceId} control={<Radio />} label={`${props.subscription.numberOfInstalment} payments x $${props.subscription.instalmentAmount} ${props.subscription.instalmentUnit.includes('every') ? props.subscription.instalmentUnit : 'every ' + props.subscription.instalmentUnit}`} />
                            </RadioGroup>
                        </div>
                    </div>
                    :
                    <div className={classes.productContainer}>
                        <div>
                            <Typography style={{maxWidth: '80%'}}>{props.subscription.name}</Typography>
                            <Typography variant='body2' color='textSecondary'>
                                {`${props.subscription.subscriptionInterval} ${props.subscription.subscriptionIntervalUnit}${props.subscription.subscriptionInterval > 1 ? 's': ''}`}                      
                            </Typography>
                            {
                                props.subscription.appointmentAllowance > 0 ??
                            <Typography variant='body2' color='textSecondary'>
                                {`Include ${props.subscription.appointmentAllowance}x online physio`} {props.subscription.appointmentAllowance > 1 ? 'appointments' : 'appointment'}
                                <br />{'& access to Universal Practice app.'}
                            </Typography>
                            }
                        </div>
                        <Typography>{`$ ${props.subscription.initialChargeAmount}`}</Typography>
                    </div>
            }
            <Coupon
                value={props.values?.coupon}
                setFieldValue={props.setFieldValue}
                onClick={() => couponProcess(props.values) }
                errorMessage={errorMessage}
                discountAmount={amountOff}
                onClearCode={clearCoupon}
            />
            <div className={classes.totalContainer}>
                <Typography>Total {amountOff.length > 0 ? `(with ${amountOff} off applied)` : ''}</Typography>
                <Typography>$ {totalPrice}</Typography>                          
            </div>
            <div className={classes.notesContainer}>
                Payment <a href={TermsConditionsLink} target='_blank' rel='noopener noreferrer' className={'TermsAndConditionsLink'}>terms & conditions</a>
            </div>
        </div>
    );
};

function mapStateToProps(state) {
    return {
        subscription: state.customerDetails.subscription,
        couponDetails: state.customerDetails.couponDetails 
    };
}

export default connect(mapStateToProps)(PaymentSummary);